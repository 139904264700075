// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-no-js": () => import("/opt/build/repo/src/templates/home.no.js" /* webpackChunkName: "component---src-templates-home-no-js" */),
  "component---src-templates-about-no-js": () => import("/opt/build/repo/src/templates/about.no.js" /* webpackChunkName: "component---src-templates-about-no-js" */),
  "component---src-templates-advisors-no-js": () => import("/opt/build/repo/src/templates/advisors.no.js" /* webpackChunkName: "component---src-templates-advisors-no-js" */),
  "component---src-templates-job-no-js": () => import("/opt/build/repo/src/templates/job.no.js" /* webpackChunkName: "component---src-templates-job-no-js" */),
  "component---src-templates-contact-no-js": () => import("/opt/build/repo/src/templates/contact.no.js" /* webpackChunkName: "component---src-templates-contact-no-js" */),
  "component---src-templates-relatertinnhold-no-js": () => import("/opt/build/repo/src/templates/relatertinnhold.no.js" /* webpackChunkName: "component---src-templates-relatertinnhold-no-js" */),
  "component---src-templates-kostnadsanalyser-no-js": () => import("/opt/build/repo/src/templates/kostnadsanalyser.no.js" /* webpackChunkName: "component---src-templates-kostnadsanalyser-no-js" */),
  "component---src-templates-innkjopsavtaler-no-js": () => import("/opt/build/repo/src/templates/innkjopsavtaler.no.js" /* webpackChunkName: "component---src-templates-innkjopsavtaler-no-js" */),
  "component---src-templates-kostnadsbesparelser-no-js": () => import("/opt/build/repo/src/templates/kostnadsbesparelser.no.js" /* webpackChunkName: "component---src-templates-kostnadsbesparelser-no-js" */),
  "component---src-templates-innkjopsradgiver-no-js": () => import("/opt/build/repo/src/templates/innkjopsradgiver.no.js" /* webpackChunkName: "component---src-templates-innkjopsradgiver-no-js" */),
  "component---src-templates-home-se-js": () => import("/opt/build/repo/src/templates/home.se.js" /* webpackChunkName: "component---src-templates-home-se-js" */),
  "component---src-templates-about-se-js": () => import("/opt/build/repo/src/templates/about.se.js" /* webpackChunkName: "component---src-templates-about-se-js" */),
  "component---src-templates-advisors-se-js": () => import("/opt/build/repo/src/templates/advisors.se.js" /* webpackChunkName: "component---src-templates-advisors-se-js" */),
  "component---src-templates-job-se-js": () => import("/opt/build/repo/src/templates/job.se.js" /* webpackChunkName: "component---src-templates-job-se-js" */),
  "component---src-templates-contact-se-js": () => import("/opt/build/repo/src/templates/contact.se.js" /* webpackChunkName: "component---src-templates-contact-se-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

